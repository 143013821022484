import {
  GET_ALL_SELECTION,
  INSERT_SELECTION,
  INSTANCE_SELECTION,
} from 'redux/actionType';

const INIT_STATE = {
  selections: [],
  selection: [],
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SELECTION:
      return {
        ...state,
        selections: action.payload,
        total_count: action.total_count,
        loading: false,
      };
    case INSTANCE_SELECTION:
      return {
        ...state,
        selection: action.payload,
        loading: false,
      };
    case INSERT_SELECTION:
      return {
        ...state,
        selection: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
