import { getStorage } from '../_helpers';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { jwt },
      } = store.getState();

      if (jwt) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${jwt}`;
        // eslint-disable-next-line no-param-reassign
        config.headers['X-Auth-Token'] = getStorage('tkn_id');
      }

      return config;
    },
    (err) => Promise.reject(err),
  );
}
